import "./AddExpenseModal.scss";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useRef } from "react";
import {
    UNCATEGORIZED_BUDGET_ID,
    useBudgets,
} from "../../Functions/FunctionsContext";

function AddExpenseModal({ show, handleClose, defaultBudgetId }) {
    const descriptionRef = useRef();
    const amountRef = useRef();
    const budgetIdRef = useRef();
    const { addExpense, budgets } = useBudgets();

    function handleSubmit(e) {
        e.preventDefault();
        addExpense({
            description: descriptionRef.current.value,
            amount: parseFloat(amountRef.current.value),
            budgetId: budgetIdRef.current.value,
        });
        handleClose();
    }

    return (
        <Modal show={show} onHide={handleClose} className="addExpenseModal">
            <Form className="addExpenseModal__form" onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>New Expense</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            ref={descriptionRef}
                            type="text"
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="amount">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                            ref={amountRef}
                            type="number"
                            min={0}
                            step={0.01}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="budgetId">
                        <Form.Label>Budget</Form.Label>
                        <Form.Select
                            defaultValue={defaultBudgetId}
                            ref={budgetIdRef}
                        >
                            <option id={UNCATEGORIZED_BUDGET_ID}>
                                Uncategorized
                            </option>
                            {budgets.map((budget) => (
                                <option key={budget.id} value={budget.id}>
                                    {budget.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <div className="addBudgetModal__container-button">
                        <button
                            type="submit"
                            className="addExpenseModal__button"
                        >
                            Add
                        </button>
                    </div>
                </Modal.Body>
            </Form>
        </Modal>
    );
}
export default AddExpenseModal;
